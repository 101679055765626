// @flow
import React, { useEffect, type StatelessFunctionalComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { useParams, useLocation, type Match } from "react-router-dom";
import { FormHeader } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework";
import {
  getGeneralFilterSaga,
  fetchGeneralFilterTargetingSaga,
  saveGeneralFilterSaga,
} from "../../actions/generalFilters";
import { setBackup, restoreBackup } from "../../actions/backup";
import { getCommonDropdownSaga } from "../../actions/dropdowns";
import GeneralFilterInfo from "../../components/GeneralFilterForm";
import GeneralFilterCampaignIn from "../../components/GeneralFilterForm/GeneralFilterCampaignIn";
import GeneralFilterCampaignOut from "../../components/GeneralFilterForm/GeneralFilterCampaignOut";
import GeneralFilterTargeting from "../../components/GeneralFilterForm/GeneralFilterTargeting";
import { GENERAL_FILTER_FORM, SAVE_GENERAL_FILTER_SAGA } from "../../helpers/constants";
import { useLoading } from "../../hooks";
// eslint-disable-next-line import/max-dependencies
import GeneralFilterModalV2 from "../../components/GeneralFilterModalV2";

type Props = {||};

const FilterInfoStep: StatelessFunctionalComponent<Props> = () => {
  const { id: pageId }: $PropertyType<Match, "params"> = useParams();
  const { pathname } = useLocation();
  const isClone = pathname.includes("/cloneV2/");
  const isCreate = pathname.includes("/create");
  const dispatch = useDispatch();
  const name: string = useSelector((state) => getFormField(state, GENERAL_FILTER_FORM, "name"));
  const isGetFormloading: boolean = useLoading(SAVE_GENERAL_FILTER_SAGA);
  const handleSaveForm = () => {
    dispatch(saveGeneralFilterSaga());
  };

  useEffect(() => {
    dispatch(setBackup(["filterInfo", "targeting", "steps"]));
    dispatch(fetchGeneralFilterTargetingSaga());
    if (!isCreate) {
      dispatch(getGeneralFilterSaga(pageId, isClone));
    }

    return () => {
      dispatch(restoreBackup());
    };
  }, [pageId]);

  useEffect(() => {
    dispatch(getCommonDropdownSaga("generalFilterPiorityList", "priorityListGF"));
  }, [pageId]);

  return (
    <>
      <FormHeader
        title={pageId ? `Edit General filter: ${name}` : "Create new General filter"}
        loading={isGetFormloading}
        onClick={handleSaveForm}
        isActionVisible
      />
      <Box display="flex" width="100%">
        <Container maxWidth="lg" mt={3}>
          <GeneralFilterInfo />
          <GeneralFilterCampaignIn />
          <GeneralFilterCampaignOut />
        </Container>
        <Container maxWidth="lg" mt={3}>
          <GeneralFilterTargeting />
        </Container>
        <GeneralFilterModalV2 />
      </Box>
    </>
  );
};

export default FilterInfoStep;
